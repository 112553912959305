import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <p className="footer__title">Fathur Rahman Fauzan</p>
        <div className="footer__social">
          <a
            href="https://www.facebook.com/fathur.fauzan/"
            className="footer__icon" target="_blank"
          >
            <i className="bx bxl-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com/fathurzoy"
            className="footer__icon" target="_blank"
          >
            <i className="bx bxl-instagram"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/fathurzoy/"
            className="footer__icon" target="_blank"
          >
            <i className="bx bxl-linkedin"></i>
          </a>
        </div>
        <p>&#169; 2021 copyright all right reserved</p>
      </footer>
    </div>
  );
};

export default Footer;
